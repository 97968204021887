/* eslint-disable camelcase */

import { useState, useEffect } from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
// material
import { Card, Table, Stack, Button, TableRow, TableBody, TableCell, Container, Typography, TableContainer, TablePagination, TextField, Select, MenuItem, Tooltip, Chip } from '@mui/material';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
// components
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import { styled } from '@mui/system';
import { SitterGroomingMoreMenu } from '../../../sections/@dashboard/sitterAllocationGrooming';

// import {backgroundDetails} from 'src/Assets/backgroundDetails.png'
import CircularProgress from '@material-ui/core/CircularProgress';

import backgroundDetails from '../../../Assets/backgroundDetails.png';
import ExcelLogo from '../../../Assets/ExcelLogo.jpg';

import Iconify from '../../../components/Iconify';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import { BookingHistoryListHead } from '../../../sections/@dashboard/bookingHistory';
// import '../../../css/Main.css'
// mock
import { exportgroomingBookingHistoryData, getGroomingBookingHistoryData, getSessionData } from '../../../common/api-service';
import NavButtons from './NavButtons';
import CustomerOrderDetails from '../CustomerOrderDetails';
import RescheduleReqActionBookingDetails from '../RescheduleReqActionBookingDetail';


// import { Margin } from '@mui/icons-material';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'booking_id', label: 'Booking ID', alignRight: false },
  { id: 'parent_name', label: 'Customer Name', alignRight: false },
  { id: 'pet_type', label: 'Pet Type', alignRight: false },
  // { id: 'is_reschedule', label: 'Rescheduled', alignRight: false },
  // { id: 'reschedule_status', label: 'Reschedule Status', alignRight: false },
  { id: 'booking_date', label: 'Booking Date', alignRight: false },
  { id: 'start_date', label: 'Start Date', alignRight: false },
  { id: 'time_slot', label: 'Time Slot', alignRight: false },
  { id: 'method_of_payment', label: 'Method of Payment', alignRight: false },
  { id: 'amount_paid', label: 'Amount', alignRight: false },
  { id: 'status_of_payment', label: 'Status of Payment', alignRight: false },
  // { id: 'add_ons', label: 'Add Ons', alignRight: false },
  { id: 'package_title', label: 'Packages', alignRight: false },
  // { id: 'parent_phone_no', label: 'Contact Number', alignRight: false },
  // { id: 'address', label: 'Address', alignRight: false },
  // { id: 'sitter_name', label: 'Sitter Name', alignRight: false },
  // { id: 'sitter_phone_no', label: 'Sitter Contact', alignRight: false },
  // { id: 'typeof_session', label: 'Type of Session', alignRight: false },
  // { id: 'sessions', label: 'Number of Session', alignRight: false },
  { id: 'completed_sessions', label: 'Completed Sessions', alignRight: false },
  // { id: 'current_session_ongoing', label: 'Ongoing Session', alignRight: false },
  // { id: 'remaining_sessions', label: 'Remaining Session', alignRight: false },
  // { id: 'offer_description', label: 'Offer Description', alignRight: false },
  // { id: 'offer_count', label: 'Offer Count', alignRight: false },
  // { id: 'offer_remaining', label: 'Offer Remaining', alignRight: false },

  { id: '' },
];

const EXCEL_TABLE_HEAD = [
  { id: 'booking_id', label: 'Booking ID', alignRight: false },
  { id: 'parent_name', label: 'Customer Name', alignRight: false },
  { id: 'pet_type', label: 'Pet Type', alignRight: false },
  { id: 'booking_date', label: 'Booking Date', alignRight: false },
  { id: 'start_date', label: 'Start Date', alignRight: false },
  { id: 'time_slot', label: 'Time Slot', alignRight: false },
  { id: 'method_of_payment', label: 'Method of Payment', alignRight: false },
  { id: 'amount_paid', label: 'Amount', alignRight: false },
  { id: 'status_of_payment', label: 'Status of Payment', alignRight: false },
  { id: 'package_title', label: 'Packages', alignRight: false },
  { id: 'completed_sessions', label: 'Completed Sessions', alignRight: false },
  { id: 'add_ons', label: 'Add Ons', alignRight: false },
  { id: 'parent_phone_no', label: 'Contact Number', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'sitter_name', label: 'Sitter Name', alignRight: false },
  { id: 'sitter_phone_no', label: 'Sitter Contact', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function GroomingBookingHistory() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedValue, setSelectedValue] = useState('all');
  const [allocationValue, setAllocationValue] = useState('');
  const [prosdata, setPropsData] = useState([]);

  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);



  const [defaultStartDate, setDefaultStartDate] = useState(dayjs().startOf('month'));
  const [defaultEndDate, setDefaultEndDate] = useState(dayjs().endOf('month').endOf('day'));

  const datefilterhandleChange = (props) => {
    setPropsData(props);
    const [start, end] = props;
    setDefaultStartDate(start);
    // setDefaultEndDate(end.add(1, 'day'))
    setDefaultEndDate(end)


  }

  const bookingHistory = () => {
    setLoading(true);
    const payload = page;
    return getGroomingBookingHistoryData(
      payload,
      searchQuery,
      allocationValue,
      defaultStartDate.add(1, 'day'),
      defaultEndDate.add(1, 'day'),
      selectedDate,
      rowsPerPage,
      defaultStartDate,
      defaultEndDate,
    ).then(
      (res) => {

        setData(res.data.booking_details);
        setFilteredData(res.data.booking_details.get_paginated);
        setLoading(false);

      },
      (err) => {
        setLoading(false);
        console.log('err data: ', err);
      }
    );
  };

  const [selectedDate, setSelectedDate] = useState('date');

  const handleChangeDate = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleChange = (event) => {

    setSelectedValue(event.target.value);
    if (event.target.value === 'all') {
      setAllocationValue('');
    } else if (event.target.value === 'allocated') {
      setAllocationValue('true');
    } else if (event.target.value === 'notAllocated') {
      setAllocationValue('false');
    }

  };

  useEffect(() => {

    bookingHistory();
    // book();
  }, [offset, page, searchQuery, allocationValue, selectedDate, rowsPerPage, show]);

  useEffect(() => {
    dataexport();
  }, [defaultStartDate, defaultEndDate])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setFilteredData([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilteredData([])
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;



  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
    }
  };

  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const openModal = (rowData) => {
    setSelectedRowData(rowData);
    setFilteredSessionData([])
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // const handleReferenceID = (booking_ref_id, _id) => {
  //   localStorage.setItem("bookingReferenceId", booking_ref_id);
  //   localStorage.setItem("s1", "All");
  //   localStorage.setItem("cancellationBookingReferenceId", _id);

  // }
  const [rescheduleShow, setRescheduleShow] = useState(false)

  const handleReferenceID = (booking_ref_id, _id , reschedule_status , request_for_rescheduled,session_status) => {
    setShow(true);
    setRescheduleShow(session_status === 'cancelled' ? false : reschedule_status === 'pending' && request_for_rescheduled === true ? true : false)
    // navigate('/dashboard/customerorderDetails', { state: { defaultStartDate, defaultEndDate} });
    localStorage.setItem("bookingReferenceId", booking_ref_id);
    localStorage.setItem("cancellationBookingReferenceId", _id);
    localStorage.setItem("s1", "Ongoing")

  }


  const handleRescReferenceID = (booking_ref_id) => {
    setShow(true);
    setRescheduleShow(true)
    console.log("bookiirerfhubvhy bv ref idddd", booking_ref_id)
    localStorage.setItem("bookingReferenceId", booking_ref_id);
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 'fit-to-content',
    bgcolor: '#e1f7f1',
    boxShadow: 10,
    borderRadius: '10px',
    border: '3px solid black',
    p: 4,
    backgroundImage: `url(${backgroundDetails})`, // Replace with the path to your image file
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
  const LeftModalGrid = styled(Grid)(({ theme }) => ({
    // backgroundImage: ' url("https://thumbs.dreamstime.com/b/default-avatar-profile-image-vector-social-media-user-icon-potrait-182347582.jpg")', // Replace with your actual image URL
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%', // Adjust the height as needed
    padding: theme.spacing(2), // Add padding as needed
    borderRight: '2px solid black', // Add border styling
    color: 'ActiveCaption',
    // Add other styling properties as needed
  }));


  const [exportData, setExportData] = useState([]);
  const dataexport = () => {
    setLoading(true);
    return exportgroomingBookingHistoryData(defaultStartDate.add(1, 'day'), defaultEndDate, selectedDate).then(
      (res) => {

        setExportData(res.data.data);
        setLoading(false);

      },
      (err) => {
        setLoading(false);
        console.log('err data: ', err);
      }
    );

  }

  const formatDataForExport = () => {
    return exportData.map((row) => [
      row.booking_ref_id,
      row.parent_name,
      // row.pet_name,
      // row.pet_name.join(' , '),
      // row.pet_type,
      row.pet_type,
      // row.pet_type.join(' , '),
      moment(row.booking_date).format('DD-MM-YYYY'),
      moment(row.start_date).format('DD-MM-YYYY'),
      row.time_slot,
      row.pay_on_service === true ? 'Pay after service' : "Online",
      row.amount_paid,
      row.payment_method === '' ? 'Not Paid' : `Paid${row.pay_on_service ? ` (${row.payment_method})` : ''}`,
      row.package_title,
      row.completed_sessions,
      // row.request_for_rescheduled === true ? "Yes" : "-",
      // row.reschedule_status === '' ? '-' : row.reschedule_status,
      // row.number_of_pets,
      // row.service_name,
      // row.payment_method === '' ? 'Pay after service' : 'Online',
      // row.payment_method === '' ? 'Not Paid' : 'Paid',
      // row.add_ons,
      row.add_ons.map((addOn) => addOn.title).join(', '),
      row.parent_phone_no,
      row.address,
      // row.reschedule_date,
      // row.reschedule_status,
      // row.reschedule_time_slot,
      row.sitter_name,
      row.sitter_phone_no,
      // row.number_of_sessions === 1 || row.number_of_sessions === 0 ? 'Single' : 'Subscription',
      // row.number_of_sessions,
      // row.ongoing_session_number,
      // row.remaining_sessions,
      // row.number_of_sessions === 1 ? '-' : row.offer_description,
      // row.number_of_sessions === 1 ? '-' : row.offer_count,
      // row.number_of_sessions === 1 ? '-' : row.offer_remaining,

      // Add other columns based on your TABLE_HEAD structure
    ]);
  };

  const handleExportToExcel = () => {
    const formattedData = formatDataForExport();
    // const ws = XLSX.utils.aoa_to_sheet([TABLE_HEAD.map((head) => head.label), ...formattedData]);
    const ws = XLSX.utils.aoa_to_sheet([EXCEL_TABLE_HEAD.map((head) => head.label), ...formattedData]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'GroomingBookingHistory');
    XLSX.writeFile(wb, 'GroomingBookingHistory.xlsx');
  };

  const ExcelButton = styled(Button)({
    backgroundColor: '#217346', // Excel logo color
    color: '#ffffff', // Text color
    '&:hover': {
      backgroundColor: '#217346', // Hover color
    },
  });
  const [selectedButton, setSelectedButton] = useState('Customer Details');
  const [sessionSelectedButton, setSessionSelectedButton] = useState(null);
  const [SessionData, setFilteredSessionData] = useState([]);

  const [popupOpen, setPopupOpen] = useState(false);

  const book = (id) => {
    setSessionSelectedButton(id);
    const payload = id;

    return getSessionData(payload).then(
      (res) => {

        setFilteredSessionData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };


  // const renderDetails = () => {
  //   switch (selectedButton) {
  //     case 'Customer Details':
  //       return (
  //         <LeftModalGrid xs={6} sx={{ padding: '14px', borderRight: 'none' }}>
  //           <Box sx={{ textAlign: 'center' }}>
  //             <Typography id="modal-modal-title" variant="h4" component="h2">
  //               Customer Details
  //             </Typography>
  //           </Box>
  //           <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mt: 2, marginLeft: '10px' }}>
  //             <p style={{ fontSize: '15px' }}>Customer Name : {selectedRowData.parent_name}</p>
  //             <p style={{ fontSize: '15px' }}>address : {selectedRowData.address}</p>
  //             <p style={{ fontSize: '15px' }}>Contact No. : {selectedRowData.parent_phone_no}</p>
  //           </Typography>
  //           <Typography id="modal-modal-description" sx={{ mt: 2, marginLeft: '10px' }}>
  //             {selectedRowData && (
  //               <div>
  //                 <p>Service Name : {selectedRowData.service_name}</p>
  //                 <p>Package Name : {selectedRowData.package_title}</p>
  //                 {/* <p>Subscription : {selectedRowData.subscription === true ? 'Yes' : 'No'}</p> */}
  //                 <p>Subscription : {selectedRowData.number_of_sessions > 1 ? 'Yes' : 'No'}</p>
  //                 <p>Amount Paid : {selectedRowData.amount_paid} INR</p>
  //                 <p>Start Date : {moment(selectedRowData.start_date).format('DD-MM-YYYY')}</p>
  //                 <p>Time slot : {selectedRowData.time_slot}</p>
  //                 <p>Sitter Name : {selectedRowData.sitter_name}</p>
  //                 <p>Sitter Contact Number : {selectedRowData.sitter_phone_no}</p>
  //               </div>
  //             )}
  //           </Typography>
  //         </LeftModalGrid>
  //       );
  //     case 'Pet Details':
  //       return (
  //         <Grid xs={6} sx={{ padding: '14px' }}>
  //           <Typography
  //             id="modal-modal-title"
  //             variant="h4"
  //             component="h2"
  //             style={{ textAlign: 'center', marginTop: '19px' }}
  //           >
  //             Pet Details
  //           </Typography>
  //           <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mt: 1, marginLeft: '60px' }}>
  //             <p style={{ fontSize: '15px' }}>Pet Name : {selectedRowData.pet_name.join(' , ')}</p>
  //           </Typography>
  //           <Typography id="modal-modal-description" sx={{ mt: 2, marginLeft: '60px', marginTop: '16px' }}>
  //             {selectedRowData && (
  //               <div>
  //                 <p>Pet Type: {selectedRowData.pet_type.join(' , ')}</p>
  //                 <p>Pet Breed: {selectedRowData.pet_breed}</p>
  //                 <p>Pet Gender: {selectedRowData.pet_gender}</p>
  //                 <p>Pet DOB: {moment(selectedRowData.pet_dob).format('DD-MM-YYYY')}</p>
  //                 <p>Pet Weight: {selectedRowData.pet_weight}</p>
  //                 <p>Pet Vaccination: {selectedRowData.pet_is_vaccinated === 'true' ? 'Yes' : 'No'}</p>
  //                 <p>Pet Aggression: {selectedRowData.pet_aggressive}</p>
  //                 <p>Pet Friendly with: {selectedRowData.pet_friendly_with?.map((addOn, i) => addOn).join(', ')}</p>
  //               </div>
  //             )}
  //           </Typography>
  //         </Grid>
  //       );
  //     case 'Sessions':
  //       return (
  //         <Stack container spacing={2} direction="column">
  //           {/* Buttons Row */}
  //           <Stack direction="row" spacing={2}>
  //             {selectedRowData?.grooming_sessions.map((row) => (
  //               <Button
  //                 key={row._id}
  //                 variant="contained"
  //                 onClick={() => book(row._id)}
  //                 style={{
  //                   marginTop: '10px',
  //                   marginLeft: '10px',
  //                   float: 'left',
  //                   backgroundColor: sessionSelectedButton === row._id ? '#16bedb' : '',
  //                   color: sessionSelectedButton === row._id ? 'white' : 'black',
  //                 }}
  //               >
  //                 {`session${row.srno}`}
  //               </Button>
  //             ))}
  //           </Stack>

  //           {/* Details Row */}
  //           <Stack direction="row">
  //             <Typography id="modal-modal-description" style={{ alignContent: 'center', marginTop: '50px' }}>
  //               {/* {console.log('SessionData', SessionData)} */}
  //               {SessionData?.map((data) => {
  //                 // console.log('data', data);
  //                 return (
  //                   <div key={data.package_title}>
  //                     <p>Package Title: {data.package_title}</p>
  //                     {/* <p>Start Date: {data.request_for_rescheduled === false ? moment(data.date).format('DD-MM-YYYY').slice(0, 10) : moment(data.reschedule_date).format('DD-MM-YYYY')}</p> */}
  //                     {data.request_for_rescheduled === false ? <p>Start Date: {data.request_for_rescheduled === false && !data.date ? '-' : moment(data.date).format('DD-MM-YYYY').slice(0, 10)}</p> :
  //                       <p>Start Date: {data.request_for_rescheduled === true && data.reschedule_status === "pending" || data.reschedule_status === "rejected" ? moment(data.date).format('DD-MM-YYYY').slice(0, 10) : moment(data.reschedule_date).format('DD-MM-YYYY')}</p>}

  //                     {/* <p>Time Slot: {data.request_for_rescheduled === false ? data.time_slot : data.reschedule_time_slot}</p> */}
  //                     {data.request_for_rescheduled === false ? <p>Time Slot: {data.request_for_rescheduled === false ? data.time_slot : '-'}</p> :
  //                       <p>Time Slot: {data.request_for_rescheduled === true && data.reschedule_status === "pending" || data.reschedule_status === "rejected" ? data.time_slot : data.reschedule_time_slot}</p>}

  //                     <p>Reschedule Status: {data.reschedule_status ? data.reschedule_status : '-'}</p>

  //                     {/* <p>Reschedule Date:{data.request_for_rescheduled === true ? moment(data.date).format('DD-MM-YYYY') : '-'}</p> */}
  //                     {data.request_for_rescheduled === true && data.reschedule_status === "pending" ? <p>Reschedule Date:{data.request_for_rescheduled === true ? moment(data.reschedule_date).format('DD-MM-YYYY') : '-'}</p> :
  //                       <p>Reschedule Date:{data.request_for_rescheduled === true && data.reschedule_status === "approved" ? moment(data.date).format('DD-MM-YYYY') : '-'}</p>}

  //                     {/* <p>Reschedule Time Slot: {data.request_for_rescheduled === true ? data.time_slot : '-'}</p> */}
  //                     {data.request_for_rescheduled === true && data.reschedule_status === "pending" ? <p>Reschedule Time Slot: {data.request_for_rescheduled === true ? data.reschedule_time_slot : '-'}</p> :
  //                       <p>Reschedule Time Slot: {data.request_for_rescheduled === true && data.reschedule_status === "approved" ? data.time_slot : '-'}</p>}

  //                   </div>
  //                 );
  //               })}
  //             </Typography>
  //           </Stack>
  //         </Stack>
  //       );

  //     default:
  //       return null;
  //   }
  // };

  return (
    <>{show ? rescheduleShow ? <RescheduleReqActionBookingDetails setShow={setShow} /> :<CustomerOrderDetails setShow={setShow} /> 
        :
      (
        <Page title="User">
          <Container>
            <NavButtons />

            <Stack
              style={{ marginBottom: '10px' }}
              spacing={2}
              direction="row-reverse"
              alignItems="center"
              sx={{ display: 'flex' }}
            >
              <Grid container style={{ display: 'flex', justifyContent: 'end' }}>
                <Grid item xs={8} sm={8} md={8} lg={6} style={{ alignContent: 'right', paddingTop: '5px' }}>
                  {/* <RangePicker onChange={datefilterhandleChange} 
              defaultValue={[defaultStartDate, defaultEndDate]}
              /> */}
                  <RangePicker
                    defaultValue={[defaultStartDate, defaultEndDate]}
                    open={popupOpen}
                    onOpenChange={(open) => {
                      if (open && defaultStartDate && defaultStartDate[0] && defaultStartDate[1]) {
                        setPopupOpen(true);
                      } else {
                        setPopupOpen(open);
                      }
                    }}
                    onChange={datefilterhandleChange}
                    renderExtraFooter={() => (
                      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "8px" }}>
                        <Button onClick={() => { setPopupOpen(false); bookingHistory(); }} variant='contained' size='small' >Submit</Button>
                      </Box>
                    )}
                  />

                  <Select
                    style={{ margin: '0px 0px  0px 6px', height: '34px' }}
                    labelId="allocation-label"
                    id="allocation"
                    value={selectedDate}
                    onChange={handleChangeDate}
                  // inputProps={{ 'aria-label': 'Select' }}
                  >
                    <MenuItem value="All" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="date">Start Date</MenuItem>
                    <MenuItem value="created_at" selected>Booking Date</MenuItem>
                    <MenuItem value="completed_on">Completed Date</MenuItem>
                    <MenuItem value="cancelled_on">Cancelled Date</MenuItem>

                    {/* <MenuItem value="reschedule_date">Reschedule Date</MenuItem> */}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={6} style={{ display: 'flex' }}>
                  <Select
                    style={{ margin: '6px 2px', height: '34px' }}
                    labelId="allocation-label"
                    id="allocation"
                    value={selectedValue}
                    onChange={handleChange}
                  // inputProps={{ 'aria-label': 'Select' }}
                  >
                    <MenuItem value="All" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="allocated">Allocated</MenuItem>
                    <MenuItem value="notAllocated">Not Allocated</MenuItem>
                  </Select>
                  <TextField
                    fullWidth
                    label="Search name or contact number or bookingId"
                    id="fullWidth"
                    InputProps={{
                      style: { margin: '6px 2px', height: '34px' },
                      endAdornment: (
                        <InputAdornment
                          position="end"
                        >
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      style: { fontSize: '16px', marginTop: '-4px' },
                    }}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value.replace(/[^A-Za-z0-9-\s]/g, ''))}
                    onKeyDown={handleKeyPress}
                  />
                </Grid>
              </Grid>
            </Stack>
            <Card>

              {loading && <CircularProgress style={{ position: 'absolute', top: '10%', left: '50%' }} />}

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  {filteredData.length === 0 ? (
                    <Typography component="div">
                      {/* <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box> */}
                      {loading ? (
                        <Box sx={{ textAlign: 'center', m: 1 }}>Wait, data is loading...</Box>
                      ) : (
                        <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                      )}
                    </Typography>
                  ) : (
                    <Table size='small'>
                      <BookingHistoryListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={data.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {filteredData.map((row) => {
                          const {
                            _id,
                            pet_name,
                            service_name,
                            start_date,
                            time_slot,
                            parent_phone_no,
                            parent_name,
                            amount_paid,
                            add_ons,
                            package_title,
                            sitter_name,
                            sitter_phone_no,
                            subscription,
                            number_of_sessions,
                            location,
                            address,
                            coordinates,
                            payment_method,
                            booking_date,
                            completed_sessions,
                            ongoing_session_number,
                            remaining_sessions,
                            pet_type,
                            pet_breed,
                            pet_gender,
                            pet_dob,
                            pet_weight,
                            pet_is_vaccinated,
                            pet_aggressive,
                            pet_friendly_with,
                            number_of_pets,
                            offer_description,
                            offer_count,
                            offer_remaining,
                            service_master_id,
                            request_for_rescheduled,
                            session_status,
                            reschedule_status,
                            pay_on_service,
                            booking_ref_id,
                            reschedule_time_slot,
                            reschedule_date,
                            session_ref_id,
                            price_paid,
                          } = row;
                          const isItemSelected = selected.indexOf(_id) !== -1;
                          const truncatedDescription = offer_description?.length > 20 ? `${offer_description?.slice(0, 20)}...` : offer_description;

                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                              onClick={() => openModal(row)}
                            >
                              {/* <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                            <Link className='details-using-ref-id' to={ reschedule_status === 'pending' && request_for_rescheduled === true ? '/dashboard/rescReqActionOrderDetail' : '/dashboard/customerorderDetails'} onClick={() => handleReferenceID(booking_ref_id, _id)}>
                              {number_of_sessions === 1 ? session_ref_id?.split('-')[0] : session_ref_id}
                            </Link>
                          </TableCell> */}
                              <TableCell sx={{ textAlign: 'center' }} onClick={() => handleReferenceID(booking_ref_id, _id ,reschedule_status , request_for_rescheduled , session_status)} >
                                {/* <Link className='details-using-ref-id' to={'/dashboard/customerorderDetails'} onClick={() => handleReferenceID(booking_ref_id, _id)}> */}
                                {/* {booking_ref_id} */}
                                <span className='booking-ref-ids'> {number_of_sessions <= 1 ? booking_ref_id : session_ref_id}</span>
                                {/* </Link> */}
                              </TableCell>
                              <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                                {parent_name}
                              </TableCell>
                              <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                                {pet_type}
                              </TableCell>
                              {/* <TableCell align="left" sx={{ textAlign: 'center' }}>{request_for_rescheduled === true ? "Yes" : "-"}</TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{reschedule_status === '' ? '-' : reschedule_status}</TableCell> */}
                              <TableCell align="left" sx={{ textAlign: 'center' }}>{moment(booking_date).format('DD-MM-YYYY')}</TableCell>
                              <TableCell align="left" sx={{ textAlign: 'center' }} style={{ width: '100px' }}>
                                {/* {moment(start_date).format('DD-MM-YYYY')}  */}
                                <span style={{ display: `${session_status === 'cancelled' ? 'none' : request_for_rescheduled === true && reschedule_status === 'pending' ? 'flex' : 'none'}` }}>
                                  <Chip size='small' style={{ height: 'fit-content', fontSize: '12px' }} label="Reschedule Requested" variant="outlined" color='error' />
                                </span>
                                <div >{moment(start_date).format('DD-MM-YYYY')}</div>
                              </TableCell>
                              <TableCell align="left" sx={{ textAlign: 'center' }}>{time_slot}</TableCell>
                              <TableCell sx={{ textAlign: 'center' }}>{pay_on_service === true ? 'Pay after service' : "Online"}</TableCell>
                              <TableCell align="left" sx={{ textAlign: 'center' }}>
                                {/* {amount_paid} */}
                                {price_paid}

                              </TableCell>
                              <TableCell align="left" sx={{ textAlign: 'center' }}>{payment_method === '' ? 'Not Paid' : `Paid${pay_on_service ? ` (${payment_method})` : ''}`}</TableCell>
                              {/* <TableCell component="th" scope="row" padding="none" sx={{ textAlign: 'center' }}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {add_ons.map((d, i) => (
                                <Typography variant="subtitle2" wordWrap>
                                  {d.title}
                                </Typography>
                              ))}
                            </Stack>
                          </TableCell> */}
                              <TableCell align="left" sx={{ textAlign: 'center' }}>{package_title}</TableCell>
                              {/* <TableCell align="left" sx={{ textAlign: 'center' }}>{parent_phone_no}</TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>
                            <a
                              style={{ textDecoration: 'none', color: '#32a852' }}
                              href={`https://www.google.com/maps?q=${location?.coordinates[0]},${location?.coordinates[1]}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View on Google Map
                            </a>
                          </TableCell> */}

                              {/* <TableCell align="left" sx={{ textAlign: 'center' }}>{sitter_name}</TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{sitter_phone_no}</TableCell> */}
                              {/* <TableCell sx={{ textAlign: 'center' }}>
                            {number_of_sessions === 1 || number_of_sessions === 0 ? 'Single' : 'Subscription'}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{number_of_sessions}</TableCell> */}
                              <TableCell sx={{ textAlign: 'center' }}>{completed_sessions}</TableCell>
                              {/* <TableCell sx={{ textAlign: 'center' }}>{ongoing_session_number}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{remaining_sessions}</TableCell> */}
                              {/* offer columns */}
                              {/* <TableCell  sx={{maxWidth: '100px', wordWrap: 'break-word', textAlign: 'center'}}>{number_of_sessions === 1 ? '-' : offer_description}</TableCell> */}
                              {/* <TableCell sx={{ textAlign: 'center', maxWidth: '100px', maxHeight: '50px', wordWrap: 'break-word' }}>
                            {number_of_sessions === 1 ? (
                              "-"
                            ) : (
                              <Tooltip title={offer_description} arrow>
                                <span>
                                  {truncatedDescription}
                                </span>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{number_of_sessions === 1 ? '-' : offer_count}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{number_of_sessions === 1 ? '-' : offer_remaining}</TableCell> */}
                              <TableCell sx={{ textAlign: 'center', position: 'sticky', right: '5px', overflowX: 'hidden', overflowY: 'hidden' }}>
                                {/* <span style={{ display: `${request_for_rescheduled === true && reschedule_status === 'pending' ? 'flex' : 'none'}` }}>
                              <Link className='details-using-ref-id' to={'/dashboard/rescReqActionOrderDetail'} onClick={() => handleReferenceID(booking_ref_id)}>
                                <Button variant='contained' size='small' >Approve/Reject</Button>
                              </Link>
                            </span> */}
                                <TableCell align="right" sx={{ textAlign: 'center', position: 'sticky', right: '5px', overflowX: 'hidden', overflowY: 'hidden' }}>
                                  <span style={{ display: `${session_status === 'completed' || session_status === 'ongoing' || session_status === 'cancelled' ? 'none' : 'flex'}` }}>
                                    {request_for_rescheduled === true && reschedule_status === 'pending' ?
                                      <span style={{ display: `${request_for_rescheduled === true && reschedule_status === 'pending' ? 'flex' : 'none'}` }}>
                                        {/* <Link className='details-using-ref-id' to={'/dashboard/rescReqActionOrderDetail'} onClick={() => handleRescReferenceID(booking_ref_id)}> */}
                                          <Button onClick={() => handleRescReferenceID(booking_ref_id)} variant='contained' size='small' >Approve/Reject</Button>
                                        {/* </Link> */}
                                      </span>
                                      :
                                      <SitterGroomingMoreMenu
                                        rowData={row}
                                        data={service_master_id}
                                        // dataDate={moment(date !=null?date:"2022-11-26T00:00:00.000Z").format('YYYY-MM-DD')}
                                        dataDate={moment(start_date != null ? start_date : "").format('DD-MM-YYYY')}
                                      />
                                    }
                                  </span>
                                </TableCell>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100, 150]}
                component="div"
                // count={data.number_of_records}
                count={data.total_number_of_records}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>

            <div style={{ textAlign: 'end', marginTop: '4%' }}>
              <ExcelButton variant="contained" disabled={prosdata == null} onClick={handleExportToExcel}>
                <img
                  src={ExcelLogo}
                  alt="Export To Excel"
                  height={28}
                  width={40}
                />
                <Typography>Export to Excel</Typography>
              </ExcelButton>
            </div>

            {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => handleButtonClick('Customer Details')}
                  style={{
                    backgroundColor: selectedButton === 'Customer Details' ? '#16bedb' : '',
                    color: selectedButton === 'Customer Details' ? 'white' : 'black',
                  }}
                  selected
                >
                  Customer Details
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => handleButtonClick('Pet Details')}
                  style={{
                    backgroundColor: selectedButton === 'Pet Details' ? '#16bedb' : '',
                    color: selectedButton === 'Pet Details' ? 'white' : 'black',
                  }}
                >
                  Pet Details
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => handleButtonClick('Sessions')}
                  style={{
                    backgroundColor: selectedButton === 'Sessions' ? '#16bedb' : '',
                    color: selectedButton === 'Sessions' ? 'white' : 'black',
                  }}
                >
                  Sessions
                </Button>
              </Grid>
            </Grid>
            {renderDetails()}
          </Box>
        </Modal> */}
          </Container>
        </Page>
      )}

    </>
  );
}
