import { api } from './service-config';

export function login(payload) {
  return api().post(`/admin/login`, JSON.stringify(payload));
}

export function getOfferingData(payload) {
  return api().get(`/admin/offerings?page=${payload}`);
}

export function addOffering(payload) {
  return api().post(`/admin/offerings`, JSON.stringify(payload));
}

export function getOfferingDataForUpdate(payload) {
  return api().get(`admin/offerings/offer_by_id?_id=${payload._id}`);
}

export function offeringsUpdate(payload) {
  return api().patch(`/admin/offerings`, JSON.stringify(payload));
}


export function rescheduleBookingDateUpdate(payload) {
  return api().patch(`/admin/grooming_reschedule/reschedule_session`, JSON.stringify(payload));
}

export function getTimeSlotsForReschedule() {
  return api().get(`/admin/grooming_time_slot`);
}

export function rescheduleBookingHistoryChangeSitter(payload) {
  return api().patch(`/admin/grooming_reschedule/is_change_sitter`, JSON.stringify(payload));
}


export function deleteOffering(payload) {
  return api().delete(`/admin/offerings?_id=${payload._id}&status=${payload.status}`);
}

export function dropDownServiceMaster() {
  return api().get(`/admin/service_master`);
}

export function getServiceMasterData() {
  return api().get(`/admin/service_master`);
}

export function getSessionCount() {
  return api().get(`/admin/grooming_sessions/total_count?service_name=Pet Grooming`);
}

export function addServiceMaster(payload) {
  return api().post(`/admin/service_master`, JSON.stringify(payload));
}

export function deleteServiceMaster(payload) {
  return api().delete(`/admin/service_master?_id=${payload._id}&status=${payload.status}`);
}

export function getserviceMasterDataForUpdate(payload) {
  return api().get(`admin/service_master/service_master_by_id?_id=${payload._id}`);
}

export function serviceMasterUpdate(payload) {
  return api().patch(`/admin/service_master`, JSON.stringify(payload));
}

export function getAddonsData(payload) {
  return api().get(`/admin/add_ons?page=${payload}`);
}

export function addAddons(payload) {
  return api().post(`/admin/add_ons`, JSON.stringify(payload));
}

export function deleteAddons(payload) {
  return api().delete(`/admin/add_ons?_id=${payload._id}&status=${payload.status}`);
}

export function getAddonsDataForUpdate(payload) {
  return api().get(`/admin/add_ons/add_ons_by_id?_id=${payload._id}`);
}

export function addonsUpdate(payload) {
  return api().patch(`/admin/add_ons`, JSON.stringify(payload));
}

export function getPackagesData(payload) {
  return api().get(`/admin/packages?page=${payload}`);
}

export function addPackages(payload) {
  return api().post(`/admin/packages`, JSON.stringify(payload));
}

export function dropDownOffering() {
  return api().get(`/admin/grooming_offerings/all`);
}

export function getAddonsDropDownData() {
  return api().get(`/admin/grooming_add_ons/all`);
}

export function deletePackages(payload) {
  return api().delete(`/admin/packages?_id=${payload._id}&status=${payload.status}`);
}

export function getPackagesDataForUpdate(payload) {
  return api().get(`/admin/packages/package_by_id?_id=${payload._id}`);
}

export function packagesUpdate(payload) {
  return api().patch(`/admin/packages`, JSON.stringify(payload));
}

export function getCouponsData() {
  return api().get(`/admin/coupons`);
}

export function getCouponsDataById(payload) {
  return api().get(`admin/coupons/coupon_by_id?_id=${payload._id}`);
}

export function couponsUpdateById(payload) {
  return api().patch(`/admin/coupons`, JSON.stringify(payload));
}

export function couponStatusUpdateById(payload) {
  return api().patch(`/admin/coupons/change_status`, JSON.stringify(payload));
}

export function deleteCoupons(payload) {
  return api().delete(`/admin/coupons?_id=${payload._id}&status=${payload.status}`);
}

export function addCoupons(payload) {
  return api().post(`/admin/coupons`, JSON.stringify(payload));
}

export function addNotification(payload) {
  return api().post(`/admin/notification_template`, JSON.stringify(payload));
}

export function notificationUpdateById(payload) {
  return api().patch(`/admin/notification_template`, JSON.stringify(payload));
}

export function getNotificationList() {
  return api().get(`/admin/notification_template`);
}

export function getNotificationById(payload) {
  return api().get(`admin/notification_template/notification_template_by_id?_id=${payload._id}`);
}

export function sendnotification(payload) {
  return api().post(`admin/notification_template/send_notification`, JSON.stringify(payload));
}

export function deleteNotificationById(payload) {
  return api().delete(`admin/notification_template?_id=${payload._id}`);
}

export function bulkImport(payload) {
  return api().post(`/admin/sitter`, JSON.stringify(payload));
}

export function getBulkUploadData(searchQuery) {
  return api().get(`/admin/sitter?search_text=${searchQuery}`);
}

export function addTimeSlots(payload) {
  return api().post(`/admin/grooming_time_slot`, JSON.stringify(payload));
}

export function updateTimeSlots(payload) {
  return api().patch(`/admin/grooming_time_slot`, JSON.stringify(payload));
}

export function getTimeSlotsById(timeslotid) {
  return api().get(`/admin/grooming_time_slot/time_slot_by_id?_id=${timeslotid}`);
}

export function getTimeSlots(payload , rowsPerPage) {
  return api().get(`/admin/grooming_time_slot?page=${payload}&limit=${rowsPerPage}`);
}

export function getSpecificTimeSlots(payload) {
  return api().get(
    // `/admin/time_slot/time_slot_by_service_master_id?service_master_id=${payload.masterId}&date=${payload.selectedDate}`
    `/admin/grooming_time_slot?date=${payload.selectedDate}&page=${payload.pageNum}`
  );
}

export function disableTimeSlot(payload) {
  return api().delete(
    `/admin/grooming_time_slot/disableTime?_id=${payload._id}&slot=${payload.slot}&status=${payload.status}`
  );
}

export function getGroomingBookingHistoryData(payload, searchQuery,allocationValue, fromDate, toDate,selectedDate,rowsPerPage, defaultStartDate, defaultEndDate) {
  const pageParameter = searchQuery ? payload : payload;
  // const fromDate1 = fromDate ? fromDate :  defaultStartDate;
  // const toDate1 = toDate ? toDate :  defaultEndDate;
  return api().get(`/admin/grooming_book_services?page=${pageParameter}&search_text=${searchQuery}&sitter_allocated=${allocationValue}&from_date=${fromDate}&to_date=${toDate}&filter=${selectedDate}&limit=${rowsPerPage}`);
}

export function getOrderDetailsData(payload) {
  return api().get(`/admin/grooming_book_services/booking_details_with_booking_ref_id?booking_ref_id=${payload}`);
}

export function getGroomingBookingSubscription(payload,searchQuery, rowsPerPage) {
  return api().get(`/admin/grooming_book_services/subscription_history?page=${payload}&search_text=${searchQuery}&limit=${rowsPerPage}`);
}

export function getSessionData(payload) {
  return api().get(`admin/grooming_sessions/get_session_details_by_id?_id=${payload}`);
}


// export function getGroomingBookingHistorySearchData(page,searchQuery) {
//   const pageParameter = searchQuery ? 0 : page;
//   return api().get(`/admin/grooming_book_services?page=${pageParameter}&search_text=${searchQuery}`);
// }

// export function getGroomingBookingHistoryAllocationData(page,allocationValue) {
//   const pageParameter = allocationValue ? 0 : page;
//   return api().get(`/admin/grooming_book_services?page=${pageParameter}&sitter_allocated=${allocationValue}`);
// }

// vivek
// export function getGroomingBookingOngoingData(payload,searchQuery,fromDate,toDate,selectedDate,rowsPerPage, defaultStartDate, defaultEndDate)
//  {
//   const pageParameter = searchQuery ? payload : payload;
//   const fromDate1 = fromDate ? fromDate :  defaultStartDate;
//   const toDate1 = toDate ? toDate :  defaultEndDate;
//   return api().get(`/admin/grooming_sessions/ongoing?page=${pageParameter}&search_text=${searchQuery}&from_date=${fromDate1}&to_date=${toDate1}&filter=${selectedDate}&limit=${rowsPerPage}`);
// }

export function getGroomingBookingOngoingData(payload,searchQuery,fromDate,toDate,selectedDate,rowsPerPage, defaultStartDate, defaultEndDate)
 {
  const pageParameter = searchQuery ? payload : payload;
  // const fromDate1 = fromDate ? fromDate :  defaultStartDate;
  // const toDate1 = toDate ? toDate :  defaultEndDate;
  return api().get(`/admin/grooming_sessions/ongoing?page=${pageParameter}&search_text=${searchQuery}&from_date=${fromDate}&to_date=${toDate}&filter=${selectedDate}&limit=${rowsPerPage}`);
}

export function getGroomingBookingOngoingSearchData(page , searchQuery) {
  const pageParameter=searchQuery ? page : page;
  return api().get(`/admin/grooming_sessions/ongoing?page=${pageParameter}&search_text=${searchQuery}`);
}

export function getGroomingBookingCompletedData(payload,searchQuery,fromDate,toDate,selectedDate,rowsPerPage, defaultStartDate, defaultEndDate) {
  const pageParameter = searchQuery ? payload : payload;
  // const fromDate1 = fromDate ? fromDate :  defaultStartDate;
  // const toDate1 = toDate ? toDate :  defaultEndDate;
  // return api().get(`/admin/grooming_sessions/completed?page=${pageParameter}&search_text=${searchQuery}`);
  return api().get(`/admin/grooming_sessions/completed?page=${pageParameter}&search_text=${searchQuery}&from_date=${fromDate}&to_date=${toDate}&filter=${selectedDate}&limit=${rowsPerPage}`);
}

// export function getGroomingBookingCompletedSearchData(page,searchQuery) {
//   const pageParameter=searchQuery?0:page;
//   return api().get(`/admin/grooming_sessions/completed?page=${pageParameter}&search_text=${searchQuery}`);
// }

export function getGroomingBookingUpcomingData(payload,searchQuery,fromDate,toDate,selectedDate,rowsPerPage, defaultStartDate, defaultEndDate) {
  const pageParameter = searchQuery ? 0 : payload;
  // const fromDate1 = fromDate ? fromDate :  defaultStartDate;
  // const toDate1 = toDate ? toDate :  defaultEndDate;
  return api().get(`/admin/grooming_sessions/upcoming?page=${pageParameter}&search_text=${searchQuery}&from_date=${fromDate}&to_date=${toDate}&filter=${selectedDate}&limit=${rowsPerPage}`);
}

export function getGroomingBookingUpcomingSearchData(page,searchQuery) {
  const pageParameter=searchQuery ? page : page;
  return api().get(`/admin/grooming_sessions/upcoming?page=${pageParameter}&search_text=${searchQuery}`);
}

export function getBlogManagementData() {
  return api().get(`/admin/blogs`);
}

export function addBlog(payload) {
  return api().post(`/admin/blogs`, JSON.stringify(payload));
}

export function getBlogDataForUpdate(payload) {
  return api().get(`/admin/blogs/blog_by_id?_id=${payload._id}`);
}

export function blogsUpdate(payload) {
  return api().patch(`/admin/blogs`, JSON.stringify(payload));
}

export function deleteBlogs(payload) {
  return api().delete(`/admin/blogs?_id=${payload._id}&status=${payload.status}`);
}

export function getSitterAllocationData() {
  return api().get(`/admin/sessions/requested_sessions`);
}

export function getBannerData() {
  return api().get(`/admin/banners`);
}

export function addBanner(payload) {
  return api().post(`/admin/banners`, JSON.stringify(payload));
}

export function getBannerDataForUpdate(payload) {
  return api().get(`/admin/banners/banner_by_id?_id=${payload._id}`);
}

export function bannerUpdate(payload) {
  return api().patch(`/admin/banners`, JSON.stringify(payload));
}

export function deleteBanner(payload) {
  return api().delete(`/admin/banners?_id=${payload._id}&icon=${payload.icon}`);
}

export function getSitterAllocationDataForAllocate(payload) {
  return api().get(`/admin/sitter/sitter_by_service_master_id?${payload.service_master_id}&${payload.start_date}`);
}
export function getRequestData() {
  return api().get(`/admin/others`);
}

export function getPetListData(searchQuery) {
  return api().get(`/admin/users/with_pets?search_text=${searchQuery}`);
}

export function getPetData(searchQuery) {
  // return api().get(`/admin/pets/with_user?search_text=${searchQuery}`);
  // return api().get(`/admin/users/with_pets?search_text=${searchQuery}`);
  return api().get(`admin/pets/with_user?search_text=${searchQuery}`);


}

export function getPetDetailsById(petId) {
  return api().get(`admin/pets/get_pets?_id=${petId}`);
}


export function getUserManagementData(searchQuery ,  page , rowsPerPage) {
  return api().get(`/admin/getAllUsers?search_text=${searchQuery}&page=${page}&limit=${rowsPerPage}`);
}

export function getUserManagementFilteredData(userType) {
  return api().get(`admin/getAllUsers?type=${userType}`);
}

export function getCalendarDataForAllocate(payload) {
  return api().get(`/admin/sitter/sitter_leaves_and_allocations?_id=${payload.sitter_id}`);
}

export function sitterAllocate(payload) {
  return api().patch(`/admin/sessions`, JSON.stringify(payload));
}

export function getFaqsData() {
  return api().get(`/admin/faq`);
}

export function deleteFaqs(payload) {
  return api().delete(`/admin/faq?_id=${payload._id}&icon=${payload.icon}`);
}

export function addFaqs(payload) {
  return api().post(`/admin/faq`, JSON.stringify(payload));
}

export function getFaqsDataForUpdate(payload) {
  return api().get(`/admin/faq/faq_by_id?_id=${payload._id}`);
}

export function faqsUpdate(payload) {
  return api().patch(`/admin/faq`, JSON.stringify(payload));
}

export function getBulkUploadDataForUpdate(payload) {
  return api().get(`/admin/sitter/sitter_by_id?_id=${payload.sitter_id}`);
}

export function bulkUploadUpdate(payload) {
  return api().patch(`/admin/sitter`, JSON.stringify(payload));
}

export function addSingleSitter(payload) {
  return api().post(`/admin/sitter/for_single_sitter_creation`, JSON.stringify(payload));
}

export function addSubLesson(payload) {
  return api().post(`/admin/sublesson`, JSON.stringify(payload));
}

export function getSubLessonData(payload) {
  return api().get(`/admin/sublesson?page=${payload}`);
}

export function getSubLessonDataForUpdate(payload) {
  return api().get(`/admin/sublesson/sublesson_by_id?_id=${payload._id}`);
}

export function subLessonUpdate(payload) {
  return api().patch(`/admin/sublesson`, JSON.stringify(payload));
}

export function deleteSubLesson(payload) {
  return api().delete(`/admin/sublesson?_id=${payload._id}&status=${payload.status}`);
}

export function addLesson(payload) {
  return api().post(`/admin/lessons`, JSON.stringify(payload));
}

export function getLessonData(payload) {
  return api().get(`/admin/lessons?page=${payload}`);
}

export function getLessonDataForUpdate(payload) {
  return api().get(`/admin/lessons/lessons_by_id?_id=${payload._id}`);
}

export function lessonUpdate(payload) {
  return api().patch(`/admin/lessons`, JSON.stringify(payload));
}

export function deleteLesson(payload) {
  return api().delete(`/admin/lessons?_id=${payload._id}&status=${payload.status}`);
}

export function dropDownSubLesson() {
  return api().get(`/admin/sublesson/all`);
}

export function dropDownLesson() {
  return api().get(`/admin/lessons/all`);
}

// Pet Grooming

export function getGroomingPackagesData(payload) {
  return api().get(`/admin/grooming_packages?page=${payload}`);
}

export function addPetGroomingPackage(payload) {
  return api().post(`/admin/grooming_packages`, JSON.stringify(payload));
}

export function addIcon(payload) {
  return api().patch('/admin/grooming_packages/add_icon', payload);
}

export function getGroomingOfferingData(payload) {
  return api().get(`/admin/grooming_offerings?page=${payload}`);
}

export function addGroomingOffering(payload) {
  return api().post(`/admin/grooming_offerings`, JSON.stringify(payload));
}

export function groomingOfferingUpdate(payload) {
  return api().patch(`/admin/grooming_offerings`, JSON.stringify(payload));
}

export function deleteGroomingOffering(payload) {
  return api().delete(`/admin/grooming_offerings?_id=${payload._id}&status=${payload.status}`);
}

export function getGroomiingOfferingDataForUpdate(payload) {
  return api().get(`/admin/grooming_offerings/offer_by_id?_id=${payload._id}`);
}

export function getGroomingPackagesDataForUpdate(payload) {
  return api().get(`/admin/grooming_packages/grooming_package_by_id?_id=${payload._id}`);
}

export function groomingPackagesUpdate(payload) {
  return api().patch(`/admin/grooming_packages`, JSON.stringify(payload));
}

export function updateIcon(payload) {
  return api().patch('/admin/grooming_packages/add_icon', payload);
}

// export function groomingPackagesUpdate(payload, formData) {
//   formData.append('json_payload', JSON.stringify(payload));
//   return api().patch(`/admin/grooming_packages`, formData);
// }

export function deletePetGroomingPackage(payload) {
  return api().delete(`admin/grooming_packages?_id=${payload._id}&status=${payload.status}`);
}

export function getGroomingAddonsData(payload) {
  return api().get(`/admin/grooming_add_ons?page=${payload}`);
}

export function addGrommingAddons(payload) {
  return api().post(`/admin/grooming_add_ons`, JSON.stringify(payload));
}

export function getGroomingAddonsDataForUpdate(payload) {
  return api().get(`/admin/grooming_add_ons/add_on_by_id?_id=${payload._id}`);
}

export function addonsGrommingUpdate(payload) {
  return api().patch(`/admin/grooming_add_ons`, JSON.stringify(payload));
}

export function deleteGrommingAddons(payload) {
  return api().delete(`/admin/grooming_add_ons?_id=${payload._id}&status=${payload.status}`);
}



export function getGroomingSitterAllocationData(payload, searchQuery, fromDate, toDate , selectedDate,rowsPerPage, defaultStartDate, defaultEndDate) {
  const pageParameter = searchQuery ? payload : payload;
  // const fromDate1 = fromDate ? fromDate :  defaultStartDate;
  // const toDate1 = toDate ? toDate :  defaultEndDate;
  return api().get(`/admin/grooming_sessions/requested_sessions?page=${pageParameter}&search_text=${searchQuery}&from_date=${fromDate}&to_date=${toDate}&filter=${selectedDate}&limit=${rowsPerPage}`);
}

export function getGroomingSitterAllocationSearchData(page, searchQuery) {
  const pageParameter = searchQuery ? page : page;
  return api().get(`/admin/grooming_sessions/requested_sessions?page=${pageParameter}&search_text=${searchQuery}`);
}


export function getGroomingSitterAllocationDataForAllocate(payload) {
  return api().get(
    `/admin/service_master/sitters_for_allocation?_id=${payload.service_master_id}&date=${payload.start_date}`
  );
}

export function getGroomingCalendarDataForAllocate(payload) {
  return api().get(`/admin/sitter/sitter_leaves_and_allocations?_id=${payload.sitter_id}`);
}

export function groomingSitterAllocate(payload) {
  return api().patch(`/admin/grooming_sessions`, JSON.stringify(payload));
}


export function getGroomingCancellationData(payload, searchQuery, fromDate, toDate, selectedDate, rowsPerPage) {
  return api().get(`/admin/cancellation?page=${payload}&search_text=${searchQuery}&from_date=${fromDate}&to_date=${toDate}&filter=${selectedDate}&limit=${rowsPerPage}`);
}

export function getGroomingCancellationSearchData(page, searchQuery) {
  const pageParameter = searchQuery ? page : page;
  return api().get(`/admin/cancellation?page=${pageParameter}&search_text=${searchQuery}`);
}


export function getGroomingCancellationHistoryData(payload, searchQuery, fromDate, toDate, selectedDate, rowsPerPage, defaultStartDate, defaultEndDate) {
  // const fromDate1 = fromDate ? fromDate :  defaultStartDate;
  // const toDate1 = toDate ? toDate :  defaultEndDate;
  return api().get(`/admin/cancellation/history?page=${payload}&search_text=${searchQuery}&from_date=${fromDate}&to_date=${toDate}&filter=${selectedDate}&limit=${rowsPerPage}`);
}

export function getGroomingCancellationHistorySearchData(page, searchQuery) {
  const pageParameter = searchQuery ? page : page;
  return api().get(`/admin/cancellation/history?page=${pageParameter}&search_text=${searchQuery}`);
}

export function GrommingCancellationAndApprove(payload) {
  return api().patch(`/admin/cancellation`, JSON.stringify(payload));
}


export function getGroomingReschduleData(payload, searchQuery, fromDate, toDate,selectedDate,rowsPerPage, defaultStartDate, defaultEndDate) {
  // const fromDate1 = fromDate ? fromDate :  defaultStartDate;
  // const toDate1 = toDate ? toDate :  defaultEndDate;
  return api().get(`/admin/grooming_reschedule?page=${payload}&search_text=${searchQuery}&from_date=${fromDate}&to_date=${toDate}&filter=${selectedDate}&limit=${rowsPerPage}`);
}

export function getGroomingReschduleSearchData(page, searchQuery) {
  const pageParameter = searchQuery ? page : page;
  return api().get(`/admin/grooming_reschedule?page=${pageParameter}&search_text=${searchQuery}`);
}

export function GrommingReschduleApprove(payloadWithChangeSitter) {
  return api().patch(`/admin/grooming_reschedule`, JSON.stringify(payloadWithChangeSitter));
}


export function getGroomingReschduleHistoryData(payload, searchQuery, fromDate, toDate, selectedDate, rowsPerPage, selectedbooking, defaultStartDate, defaultEndDate) {
  // const fromDate1 = fromDate ? fromDate :  defaultStartDate;
  // const toDate1 = toDate ? toDate :  defaultEndDate;
  return api().get(`/admin/grooming_reschedule/history?page=${payload}&search_text=${searchQuery}&from_date=${fromDate}&to_date=${toDate}&filter=${selectedDate}&limit=${rowsPerPage}&status=${selectedbooking}`);
}

export function getGroomingReschduleHistorySearchData(page, searchQuery) {
  const pageParameter = searchQuery ? page : page;
  return api().get(`/admin/grooming_reschedule/history?page=${pageParameter}&search_text=${searchQuery}`);
}

export function GrommingTopPetCare(payload) {
  return api().patch(`/admin/sitter/is_top_pet_carers`, JSON.stringify(payload));
}
export function getContactUsData(payload) {
  return api().get(`admin/contact_us/user?page=${payload}`);
}
export function getContactUsSitterData(payload) {
  return api().get(`/admin/contact_us/sitter?page=${payload}`);
}

// Dog Training Packages

export function getTrainingPackagesData(payload) {
  return api().get(`/admin/training_packages?page=${payload}`);
}

export function addTrainingPackage(payload) {
  return api().post(`/admin/training_packages`, JSON.stringify(payload));
}

export function trainingPackagesUpdate(payload) {
  return api().patch(`/admin/training_packages`, JSON.stringify(payload));
}



export function getTrainingOfferingsData(payload) {
  return api().get(`/admin/training_offerings?page=${payload}`);
}

export function getTrainingPackagesDataForUpdate(payload) {
  return api().get(`/admin/training_packages/package_by_id?_id=${payload._id}`);
}

export function getDogTrainingCancellationData(payload) {
  return api().get(`/admin/cancellation/training_requests?page=${payload}`);
}
export function TrainingCancellationAndApprove(payload) {
  return api().patch(`/admin/cancellation`, JSON.stringify(payload));
}

export function getTrainingBookingHistoryData(payload) {
  return api().get(`/admin/training_book_services?page=${payload}`);
}

export function getTrainingBookingCompletedData() {
  return api().get(`/admin/training_book_services/completed`);
}
export function getTrainingBookingOngoingData() {
  return api().get(`/admin/training_book_services/ongoing`);
}
export function getTrainingBookingUpcomingData() {
  return api().get(`/admin/training_book_services/upcoming`);
}
export function getTrainingCancellationHistoryData(payload) {
  return api().get(`/admin/cancellation/training_history?page=${payload}`);
}
export function TrainingReschduleApprove(payload) {
  return api().patch(`/admin/training_reschedule`, JSON.stringify(payload));
}
export function getTrainingReschduleData(payload) {
  return api().get(`/admin/training_reschedule?page=${payload}`);
}
export function getTrainingReschduleHistoryData(payload) {
  return api().get(`/admin/training_reschedule/history?page=${payload}`);
}

// Training Lesson

export function getTrainingLessonData(payload) {
  return api().get(`/admin/training_lessons?page=${payload}`);
}

export function addTrainingLesson(payload) {
  return api().post(`/admin/training_lessons`, JSON.stringify(payload));
}

export function getTrainingLessonDataForUpdate(payload) {
  return api().get(`/admin/training_lessons/lesson_by_id?_id=${payload._id}`);
}

export function trainingLessonUpdate(payload) {
  return api().patch(`/admin/training_lessons`, JSON.stringify(payload));
}

export function deleteTrainingLesson(payload) {
  return api().delete(`/admin/training_lessons?_id=${payload._id}&status=${payload.status}`);
}

// Training Sub Lesson

export function getTrainingSubLessonData(payload) {
  return api().get(`/admin/training_sublessons?page=${payload}`);
}

export function addTrainingSubLesson(payload) {
  return api().post(`/admin/training_sublessons`, JSON.stringify(payload));
}

export function getTrainingSubLessonDataForUpdate(payload) {
  return api().get(`/admin/training_sublessons/sublesson_by_id?_id=${payload._id}`);
}

export function subTrainingLessonUpdate(payload) {
  return api().patch(`/admin/training_sublessons`, JSON.stringify(payload));
}

export function deleteTrainingSubLesson(payload) {
  return api().delete(`/admin/training_sublessons?_id=${payload._id}&status=${payload.status}`);
}
// Training Time Slot

export function addTrainingTimeSlots(payload) {
  return api().post(`/admin/training_time_slot`, JSON.stringify(payload));
}

export function getTrainingTimeSlots(payload) {
  return api().get(`/admin/training_time_slot?page=${payload}`);
}

export function getTrainingSpecificTimeSlots(payload) {
  return api().get(
    // `/admin/time_slot/time_slot_by_service_master_id?service_master_id=${payload.masterId}&date=${payload.selectedDate}`
    `/admin/training_time_slot?date=${payload.selectedDate}&page=${payload.pageNum}`
  );
}

// Training Sitter Allocation

export function getTrainingSitterAllocationData() {
  return api().get(`/admin/training_book_services/requested_bookings`);
}

export function getTrainingSitterAllocationDataForAllocate(payload) {
  return api().get(
    `/admin/service_master/sitters_for_training_allocation?_id=${payload.service_master_id}&date=${payload.start_date}`
  );
}

export function getTrainingCalendarDataForAllocate(payload) {
  return api().get(`/admin/sitter/sitter_leaves_and_allocations?_id=${payload.sitter_id}`);
}

export function trainingSitterAllocate(payload) {
  return api().patch(`/admin/training_book_services`, JSON.stringify(payload));
}


export function getPetBoardingEnquiryData(payload,rowsPerPage,searchQuery,fromDate,toDate,selectedDate,) {
  return api().get(`/admin/service_enquiry?page=${payload}&limit=${rowsPerPage}&service_master_id=62f640ca7f976c2292c575bb&search_text=${searchQuery}&from_date=${fromDate}&to_date=${toDate}&filter=${selectedDate}`);
}

export function getPetSettingEnquiryData(payload,rowsPerPage,searchQuery,fromDate,toDate,selectedDate,) {
  return api().get(`/admin/service_enquiry?page=${payload}&limit=${rowsPerPage}&service_master_id=62f635738c3d56133889bdd9&search_text=${searchQuery}&from_date=${fromDate}&to_date=${toDate}&filter=${selectedDate}`);
}
export function getPetWalkingEnquiryData(payload,rowsPerPage,searchQuery,fromDate,toDate,selectedDate,) {
  return api().get(`/admin/service_enquiry?page=${payload}&limit=${rowsPerPage}&service_master_id=62d940eeb74707623d102301&search_text=${searchQuery}&from_date=${fromDate}&to_date=${toDate}&filter=${selectedDate}`);
}

export function getDogTrainingEnquiryData(payload,rowsPerPage,searchQuery,fromDate,toDate,selectedDate,) {
  return api().get(`/admin/service_enquiry?page=${payload}&limit=${rowsPerPage}&service_master_id=62f646cde874f755ae088610&search_text=${searchQuery}&from_date=${fromDate}&to_date=${toDate}&filter=${selectedDate}`);
}

export function getOthersEnquiryData(payload,rowsPerPage,searchQuery,fromDate,toDate,selectedDate,) {
  return api().get(`/admin/others?page=${payload}&limit=${rowsPerPage}&search_text=${searchQuery}&from_date=${fromDate}&to_date=${toDate}&filter=${selectedDate}`);
}


export function getAllSessionsByBookingId(bookingId) {
  return api().get(`/admin/grooming_sessions/get_all_session_details?booking_id=${bookingId}`);
}

export function getIncompleteOrdersData(payload,searchQuery,fromDate,toDate,selectedDate,rowsPerPage) {
  const pageParameter = searchQuery ? 0 : payload;
  // return api().get(`/admin/order_management/incomplete_orders?page=${pageParameter}&search_text=${searchQuery}&limit=${rowsPerPage}`);
  return api().get(`/admin/order_management/incomplete_orders?search_text=${searchQuery}`);

}

export function getRazorPayOrdersData(payload,searchQuery,rowsPerPage) {
  const pageParameter = searchQuery ? 0 : payload;
  return api().get(`/admin/order_management/orders_data?search_text=${searchQuery}&page=${pageParameter}&limit=${rowsPerPage}`);
}

export function getRazorPayPaymentsData(payload,searchQuery,rowsPerPage) {
  const pageParameter = searchQuery ? 0 : payload;
  return api().get(`/admin/order_management/payments_data?search_text=${searchQuery}&page=${pageParameter}&limit=${rowsPerPage}`);
}

//
export function bookingCancelationRequest(payload) {
// console.log('payload :', payload);
  return api().patch(`/admin/cancellation/cancel_grooming_session`, JSON.stringify(payload));
}


export function addRazorPayWebCredential(payload) {
// console.log('addRazorPayWebCredential :', payload);
  return api().post(`/admin/razorpay_credentials`, JSON.stringify(payload));
}

export function getRazorPayWebCredentials() {
  return api().get(`/admin/razorpay_credentials`);
}

export function updateRazorPayKeySetting(payload) {
// console.log('updateRazorPaySetting :', payload);
  return api().patch(`/admin/razorpay_credentials`, JSON.stringify(payload));
}



export function getAllSitterAllocationData(payload) {

  return api().get(`/admin/grooming_sessions/requested_sessions?data=all`);
}

export function getGroomingBookingOngoingAllData(rowsPerPage,page)
 {
  return api().get(`/admin/grooming_sessions/ongoing?page=${page}&limit=${rowsPerPage}`);
}

export function exportgroomingBookingHistoryData(defaultStartDate, defaultEndDate, selectedDate)
 {
  return api().get(`/admin/grooming_book_services/all_sessions_for_export?from_date=${defaultStartDate}&to_date=${defaultEndDate}&filter=${selectedDate}`);
}

export function exportgroomingBookingOngoingData(defaultStartDate, defaultEndDate, selectedDate)
 {
  return api().get(`/admin/grooming_sessions/ongoing_sessions_for_export?from_date=${defaultStartDate}&to_date=${defaultEndDate}&filter=${selectedDate}`);
}

export function exportgroomingBookingUpcomingData(defaultStartDate, defaultEndDate, selectedDate)
 {
  return api().get(`/admin/grooming_sessions/upcoming_sessions_for_export?from_date=${defaultStartDate}&to_date=${defaultEndDate}&filter=${selectedDate}`);
}

export function exportgroomingBookingCompletedData(defaultStartDate, defaultEndDate,selectedDate)
 {
  return api().get(`/admin/grooming_sessions/completed_sessions_for_export?from_date=${defaultStartDate}&to_date=${defaultEndDate}&filter=${selectedDate}`);
}

  // GeoFences

export function checkPolygon() {

  return api().get(`admin/Geofence/checkPolygon`);
}

export function getGeoFence() {

  return api().get(`admin/Geofence/getGeoFence`);
}

export function addFences(payload) {
  return api().post(`admin/GeoFence/api/addFences`, JSON.stringify(payload));
}

export function editFences(payload) {
  return api().put(`admin/Geofence/editPolygon`, JSON.stringify(payload));
}

export function getPolygonList() {
  return api().get(`admin/Geofence/getGeoPolygon`);
}

export function deleteGeoFence(payload) {
  return api().delete(`admin/Geofence/deleteRegion?id=${payload.id}`);
}


export function addPetVaccines(payload) {
  return api().post(`/admin/vaccines`, JSON.stringify(payload));
}

export function getVaccineList() {
  return api().get(`/admin/vaccines`);
}

export function deleteVaccine(payload) {
  return api().delete(`/admin/vaccines?_id=${payload._id}`);
}

export function updateVaccineData(payload) {
  return api().patch(`/admin/vaccines`, JSON.stringify(payload));
}

export function getVaccineDataByID(id) {
  return api().get(`/admin/vaccines/vaccine_by_id?_id=${id}`);
}

export function enquiryUpdateById(payload) {
  return api().patch(`/admin/service_enquiry`, JSON.stringify(payload));
}

export function othersUpdateById(payload) {
  return api().patch(`admin/others`, JSON.stringify(payload));
}