/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  InputAdornment,
  Button,
  CircularProgress
} from '@mui/material';

import Box from '@mui/material/Box';
import SearchIcon from '@material-ui/icons/Search';
import * as XLSX from 'xlsx';
import ExcelLogo from '../../Assets/ExcelLogo.jpg';
import { styled } from '@mui/system';
import moment from 'moment';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { UserManagementListHead } from '../../sections/@dashboard/userManagement';
// mock
import { getUserManagementData, getUserManagementFilteredData } from '../../common/api-service';
import UserManagementDetails from './UserManagementDetails';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'city', label: 'Location', alignRight: false },
  { id: 'created_at', label: 'Created At', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function UserManagement() {
  const [page, setPage] = useState(0);


  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [show,setShow]=useState(false);
  const [showUserManagementDetails,setShowUserManagementDetails]=useState(false);
  const [rowDetails,setRowDetails]=useState(null);
  const offer = () => {
    return getUserManagementData(searchQuery ,  page , rowsPerPage).then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data.get_paginated);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    offer();
  }, [offset , searchQuery ,  page , rowsPerPage]);

  const handleUserManagementSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

 const handleDetails=(row)=>{
    setRowDetails(row);
    setShowUserManagementDetails(true);
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const EXCEL_TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'phone', label: 'Phone', alignRight: false },
    { id: 'city', label: 'Location', alignRight: false },
    { id: 'created_at', label: 'Created At', alignRight: false },
  ];

  const formatDataForExport = () => {
    return filteredData.map((row) => [
      row?.name,
      row?.email,
      row?.phone,
      row?.city,
      row?.created_at
     
     
    ]);
  };

  const handleExportToExcel = () => {
    const formattedData = formatDataForExport();
    // const ws = XLSX.utils.aoa_to_sheet([TABLE_HEAD.map((head) => head.label), ...formattedData]);
    const ws = XLSX.utils.aoa_to_sheet([EXCEL_TABLE_HEAD.map((head) => head.label), ...formattedData]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'GroomingBookingCompleted');
    XLSX.writeFile(wb, 'GroomingBookingCompleted.xlsx');
  };

  const ExcelButton = styled(Button)({
    backgroundColor: '#217346', // Excel logo color
    color: '#ffffff', // Text color
    '&:hover': {
      backgroundColor: '#217346', // Hover color
    },
  });

  return (
<>
    {
      showUserManagementDetails? (
          <UserManagementDetails rowDetails={rowDetails}  setShowUserManagementDetails={setShowUserManagementDetails}  />
      ):(
        <Page title="User">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              User Management
            </Typography>
  
            <TextField
                  label="Enter name/contact number"
                  id="fullWidth"
                  InputProps={{
                    style: { margin: '1px 18px', height: '34px', marginRight: '30px' },
                    endAdornment: (
                      <InputAdornment
                        position="end"
                      >
                        <SearchIcon style={{ position: 'relative', left: '-5px' }} />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: { fontSize: '14px', marginTop: '-8px', marginLeft: '18px' },
                  }}
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value.replace(/[^A-Za-z0-9]/g, ''));
                    setPage(0); // Set the page number to 0
                  }}                
                />
          </Stack>
  
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                {filteredData.length === 0 ? (
                  <Typography component="div">
                    <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                    <CircularProgress sx={{marginLeft:'50%'}}/>
                  </Typography>
                ) : (
                  <Table size='small'>
                    <UserManagementListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={data.length}
                      numSelected={selected.length}
                      onUserManagementSort={handleUserManagementSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredData.map((row) => {
                        const { _id, name, email, phone, city ,user_created_at , total_number_of_records} = row;
                        const isItemSelected = selected.indexOf(_id) !== -1;
  
                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                            // sx={{cursor:"pointer"  }}
                           
                          >
                            <TableCell  component="th" scope="row" sx={{ textAlign: 'center',padding:"5px" }}  onClick={()=>{handleDetails(row)}}>
                                <Typography variant="subtitle2" noWrap className='booking-ref-ids'>
                                  {name}
                                </Typography>
                            </TableCell>
                            <TableCell align="left" sx={{ textAlign: 'center',padding:"5px" }}>{email}</TableCell>
                            <TableCell align="left" sx={{ textAlign: 'center',padding:"5px" }}>{phone}</TableCell>
                            <TableCell align="left" sx={{ textAlign: 'center',padding:"5px" }}>{city}</TableCell>
                            <TableCell align="left" sx={{ textAlign: 'center',padding:"5px" }}>{moment.utc(row.user_created_at).format('DD-MM-YYYY hh:mm A')}</TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </Scrollbar>
  
            <TablePagination
              rowsPerPageOptions={[5, 10, 25,50,100]}
              component="div"
              count={data.total_number_of_records}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
          <div style={{ textAlign: 'end', marginTop: '4%' }}>
            <ExcelButton variant="contained" disabled={data == null} onClick={handleExportToExcel}>
              <img
                // src="https://wallpapers.com/images/hd/excel-microsoft-2013-application-logo-oo7x8r2rnik4ohlo.jpg"
                src={ExcelLogo}
                alt="Export To Excel"
                height={28}
                width={40}
              />
              <Typography>Export to Excel</Typography>
            </ExcelButton>
          </div>
        </Container>
      </Page>
      )
    }
    </>
   
  );
}
