// component

import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;



const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: getIcon('eva:pie-chart-2-fill'),
  // },
  {
    title: 'Service Master',
    path: '/dashboard/serviceMaster',
    icon: getIcon('eva:pie-chart-2-fill'),
  },

  {
    icon: getIcon('eva:alert-triangle-fill'),
    title: 'Pet Grooming',
    children: [
      {
        icon: getIcon('codicon:circle-filled'),
        title: 'Grooming Booking',
        path: '/dashboard/groomingBooking',
        subchildren: [
          {
            icon: getIcon('codicon:circle-small-filled'),
            title: 'All',
            path: '/dashboard/groomingBookingHistory',
          },
          {
            icon: getIcon('codicon:circle-small-filled'),
            title: 'Ongoing',
            path: '/dashboard/groomingBookingOngoing',
          },
          {
            icon: getIcon('codicon:circle-small-filled'),
            title: 'Upcoming',
            path: '/dashboard/groomingBookingUpcoming',
          },
          {
            icon: getIcon('codicon:circle-small-filled'),
            title: 'Completed',
            path: '/dashboard/groomingBookingCompleted',
          },
          {
            icon: getIcon('codicon:circle-small-filled'),
            title: 'Cancellation History',
            path: '/dashboard/groomingCancellationHistory',
          },
        ]
      },
      {
        icon: getIcon('codicon:circle-filled'),
        title: 'Reschedule',
        path: '/dashboard/reschedule',
        subchildren: [
          {
            icon: getIcon('codicon:circle-small-filled'),
            title: 'Reschedule Request',
            path: '/dashboard/groomingReschduleBooking',
          },
          {
            icon: getIcon('codicon:circle-small-filled'),
            title: 'Reschedule History',
            path: '/dashboard/groomingReschduleHistory',
          },
        ]
      },
      {
        icon: getIcon('codicon:circle-filled'),
        title: 'Pet Grooming Packages',
        path: '/dashboard/petGrooming',
      },
      {
        icon: getIcon('codicon:circle-filled'),
        title: 'Grooming Time Slot',
        path: '/dashboard/groomingTimeSlotDetails',
      },
      {
        icon: getIcon('codicon:circle-filled'),
        title: 'Sitter Allocations',
        path: '/dashboard/groomingSitterAllocation',
      },
      // {
      //   title: 'Cancellation Requests',
      //   path: '/dashboard/groomingCancellation',
      // },
      {
        icon: getIcon('codicon:circle-filled'),
        title: 'Subscription',
        path: '/dashboard/groomingSubscription',
      },
      {
        title: 'Incomplete Bookings',
        path: '/dashboard/incompleteBookings',
        icon: getIcon('carbon:incomplete-warning'),
      },
      {
        title: 'RazorPay Transaction',
        icon: getIcon('simple-icons:razorpay'),
        subchildren: [
          {
            title: 'Order Data',
            icon: getIcon('codicon:circle-small-filled'),
            path: '/dashboard/razorPayOrder',
          },
          {
            title: 'Payment Data',
            icon: getIcon('codicon:circle-small-filled'),
            path: '/dashboard/razorPayPayment',
          },
        ],
    
      },
    ]
  },

  {
    icon: getIcon('eva:alert-triangle-fill'),
    title: 'Dog Training',
    children: [
      {
        title: 'Dog Training Packages',
        path: '/dashboard/dogTraining',
      },

      {
        title: 'Training Time Slot',
        path: '/dashboard/trainingTimeSlotDetails',
      },
      {
        title: 'Sitter Allocation',
        path: '/dashboard/trainingSitterAllocation',
      },
      {
        title: "Training Booking History",
        path: '/dashboard/trainingBookingHistory',
      },
      {
        title: "Cancellation Request",
        path: '/dashboard/trainingCancellation',
      },
      {
        title: "Cancellation History",
        path: '/dashboard/trainingCancellationHistory',
      },
      {
        title: "Reschedule Booking",
        path: '/dashboard/trainingReschduleBooking',
      },
      {
        title: "Reschedule History",
        path: '/dashboard/trainingReschduleHistory',
      },
      {
        title: 'Lesson',
        path: '/dashboard/trainingLesson',
      },
      {
        title: 'Sub Lesson',
        path: '/dashboard/trainingSubLesson',
      },
    ],
  },

  {
    icon: getIcon('eva:alert-triangle-fill'),
    title: 'Sitter',
    children: [
      {
        title: 'Sitter List',
        path: '/dashboard/bulk_upload_details',
      },
      {
        title: 'Upload Sitter',
        path: '/dashboard/bulk_upload',
      },
    ],
  },
  {
    icon: getIcon('mdi:ticket-percent'),
    title: 'Coupons',
    children: [
      {
        title: 'Add Coupons',
        path: '/dashboard/add_coupons',
      },
      {
        title: 'Coupons',
        path: '/dashboard/coupons',
      },
    ],
  },
  {
    icon: getIcon('mdi:comment-question'),
    title: 'Enquiry',
    children: [
      {
        title: 'Pet Boarding',
        path: '/dashboard/petboarding',
      },
      {
        title: 'Dog Walking',
        path: '/dashboard/dogwalking',
      },
      {
        title: 'Pet Sitting',
        path: '/dashboard/petsetting',
      },
      {
        title: 'Dog Training',
        path: '/dashboard/dogtrainings',
      },
      {
        title: 'Others',
        path: '/dashboard/others',
      },
    ],
  },
  {
    icon: getIcon('clarity:notification-solid'),
    title: 'Notifications',
    children: [
      {
        title: 'Add Notification',
        path: '/dashboard/notification',
      },
      {
        title: 'Notification List',
        path: '/dashboard/notificationlist',
      },
    ],
  },
  // {
  //   title: 'Incomplete Bookings',
  //   path: '/dashboard/incompleteBookings',
  //   icon: getIcon('carbon:incomplete-warning'),
  // },
  // {
  //   title: 'RazorPay Transaction',
  //   icon: getIcon('simple-icons:razorpay'),
  //   children: [
  //     {
  //       title: 'Order Data',
  //       icon: getIcon('codicon:circle-small-filled'),
  //       path: '/dashboard/razorPayOrder',
  //     },
  //     {
  //       title: 'Payment Data',
  //       icon: getIcon('codicon:circle-small-filled'),
  //       path: '/dashboard/razorPayPayment',
  //     },
  //   ],

  // },
  {
    title: 'Blog Management',
    path: '/dashboard/blogManagement',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Banner Management',
    path: '/dashboard/banner',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Request Module',
    path: '/dashboard/request',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Pet List',
    path: '/dashboard/petList',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'User Management',
    path: '/dashboard/userManagement',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Faqs',
    path: '/dashboard/faqs',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Top Pet Care',
    path: '/dashboard/topPetCare',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Contact Us (Customer)',
    path: '/dashboard/contactUs',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Contact Us (Sitter)',
    path: '/dashboard/sitterContactUs',
    icon: getIcon('eva:pie-chart-2-fill'),
  },

  {
    title: 'Geo Fence',
    path: '/dashboard/geoFenceArea',
    icon: getIcon('wpf:geofence'),
  },

  {
    icon: getIcon('healthicons:syringe-vaccine'),
    title: 'Pet Vaccination',
    children: [
      {
        title: 'Add Pet Vaccine',
        path: '/dashboard/addPetVaccine',
      },
      {
        title: 'Vaccine List',
        path: '/dashboard/vaccineList',
      },
    ],
  },

  // {
  //   title: 'Web Setting',
  //   icon: getIcon('icon-park-twotone:setting-web'),
  //   children: [
  //     {
  //       title: 'RazorPay Setting',
  //       icon: getIcon('codicon:circle-small-filled'),
  //       path: '/dashboard/razorPaySetting',
  //     },
      
  //   ],

  // },
];

export default navConfig;
