/* eslint-disable camelcase */

import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import moment from 'moment';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  TextField,
  InputAdornment,
  Button,
  Modal,
  Select,
  MenuItem
} from '@mui/material';

import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@mui/material/Box';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

import backgroundDetails from '../../../Assets/backgroundDetails.png'
import ExcelLogo from '../../../Assets/ExcelLogo.jpg';
// components
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import { BookingCompletedListHead } from '../../../sections/@dashboard/bookingCompleted';
// mock
import { exportgroomingBookingCompletedData, getGroomingBookingCompletedData } from '../../../common/api-service';
import BackButton from '../../../components/BackButton';
import CustomerOrderDetails from '../CustomerOrderDetails';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'booking_id', label: 'Booking Id', alignRight: false },
  { id: 'parent_name', label: 'Customer Name', alignRight: false },
  // { id: 'pet_name', label: 'Pet Name', alignRight: false },
  { id: 'pet_type', label: 'Pet Type', alignRight: false },
  // { id: 'no_of_pets', label: 'number of Pets', alignRight: false },
  // { id: 'service_name', label: 'Service Name', alignRight: false },
  { id: 'completed_on', label: 'Completed On', alignRight: false },
  { id: 'start_date', label: 'Start Date', alignRight: false },
  { id: 'time_slot', label: 'Time Slot', alignRight: false },
  { id: 'booking_date', label: 'Booking Date', alignRight: false },
  { id: 'method_of_payment', label: 'Method of Payment', alignRight: false },
  { id: 'price_paid', label: 'Amount', alignRight: false },
  { id: 'status_of_payment', label: 'Status of Payment', alignRight: false },
  // { id: 'add_ons', label: 'Add Ons', alignRight: false },
  { id: 'package_title', label: 'Packages', alignRight: false },
  // { id: 'user_phone_no', label: 'Contact Number', alignRight: false },
  // { id: 'address', label: 'Address', alignRight: false },
  { id: 'sitter_name', label: 'Sitter Name', alignRight: false },
  // { id: 'sitter_phone_no', label: 'Sitter Contact', alignRight: false },
  // { id: 'typeof_session', label: 'Type of Session', alignRight: false },
  // { id: 'sessions', label: 'Number of Session', alignRight: false },
  { id: 'completed_sessions', label: 'Completed Sessions', alignRight: false },
  // { id: 'current_session_ongoing', label: 'Ongoing Session', alignRight: false },
  // { id: 'remaining_sessions', label: 'Remaining Session', alignRight: false },
  { id: 'view_photos', label: 'View Photos', alignRight: false },

  { id: '' },
];

const EXCEL_TABLE_HEAD = [
  { id: 'booking_id', label: 'Booking Id', alignRight: false },
  { id: 'parent_name', label: 'Customer Name', alignRight: false },
  { id: 'pet_type', label: 'Pet Type', alignRight: false },
  { id: 'completed_on', label: 'Completed On', alignRight: false },
  { id: 'start_date', label: 'Start Date', alignRight: false },
  { id: 'time_slot', label: 'Time Slot', alignRight: false },
  { id: 'booking_date', label: 'Booking Date', alignRight: false },
  { id: 'method_of_payment', label: 'Method of Payment', alignRight: false },
  { id: 'price_paid', label: 'Amount', alignRight: false },
  { id: 'status_of_payment', label: 'Status of Payment', alignRight: false },
  { id: 'package_title', label: 'Packages', alignRight: false },
  { id: 'sitter_name', label: 'Sitter Name', alignRight: false },
  { id: 'completed_sessions', label: 'Completed Sessions', alignRight: false },
  { id: 'before', label: ' Pet Photo Before Grooming', alignRight: false },
  { id: 'equipment', label: 'Pet Photo With Equipment', alignRight: false },
  { id: 'after', label: 'Pet Photo After Grooming', alignRight: false },

  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_data) => _data.pet_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function BookingCompleted() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  const [prosdata, setPropsData] = useState([]);

  const [defaultStartDate, setDefaultStartDate] = useState(dayjs().startOf('week'));
  const [defaultEndDate, setDefaultEndDate] = useState(dayjs());

  const [show,setShow]=useState(false);



  const [popupOpen, setPopupOpen] = useState(false);

  // const datefilterhandleChange = (props) => {
  //   if (props) {
  //     const [start, end] = props;
  //     setFromDate(start);
  //     setToDate(end);
  //   } else {
  //     setFromDate('');
  //     setToDate('');
  //   }
  // };

  const datefilterhandleChange = (props) => {
    setPropsData(props);
    const [start, end] = props;

    setDefaultStartDate(start);
    setDefaultEndDate(end)

  }


  const bookingCompleted = () => {
    setLoading(true);
    const payload = page;
    return getGroomingBookingCompletedData(payload, searchQuery, defaultStartDate.add(1, 'day'), defaultEndDate.add(1, 'day'), selectedDate, rowsPerPage, defaultStartDate, defaultEndDate).then(
      (res) => {
        setData(res.data.data);
        // setFilteredData(res.data.data);
        setFilteredData(res.data.data.get_paginated);
        setLoading(false);
        // console.log("res.data.data.get_paginated",res.data.data.get_paginated)

      },
      (err) => {
        setLoading(false);
        console.log('err data: ', err);
      }
    );
  };

  const [selectedDate, setSelectedDate] = useState('completed_on');
  const handleChangeDate = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    bookingCompleted();
  }, [offset, page, searchQuery, rowsPerPage, fromDate, toDate, selectedDate , show]);

  useEffect(() =>{
    dataexport();
  },[defaultStartDate, defaultEndDate])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleReferenceID = (booking_ref_id, _id) => {
  //   localStorage.setItem("bookingReferenceId", booking_ref_id);
  //   localStorage.setItem("s1", "Completed");
  //   localStorage.setItem("cancellationBookingReferenceId", _id);

  // }

  const handleReferenceID = (booking_ref_id, _id) => {
    setShow(true);
    // navigate('/dashboard/customerorderDetails', { state: { defaultStartDate, defaultEndDate} });
    localStorage.setItem("bookingReferenceId", booking_ref_id);
    localStorage.setItem("cancellationBookingReferenceId", _id);
    localStorage.setItem("s1", "Ongoing")

  }
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  // const handleSearch = () => {
  //   return getGroomingBookingCompletedSearchData(page, searchQuery).then(
  //     (res) => {
  //       setData(res.data.data);
  //       setFilteredData(res.data.data);
  //     },
  //     (err) => {
  //       console.log('err data: ', err);
  //     }
  //   );
  // };

  // const handleKeyPress = (e) => {
  //   if (e.key === 'Enter') {
  //     handleSearch();
  //   }
  // };

  const [exportData, setExportData] = useState([]);
  const dataexport = () =>{
    setLoading(true);
    return exportgroomingBookingCompletedData(defaultStartDate.add(1,'day'),defaultEndDate,selectedDate).then(
      (res) => {

        setExportData(res.data.data);
        setLoading(false);

      },
      (err) => {
        setLoading(false);
        console.log('err data: ', err);
      }
    );

  }


  const formatDataForExport = () => {
    return exportData.map((row) => [
      row.no_of_sessions <= 1 ? row.booking_ref_id : row.session_ref_id,
      row.customer_name,
      // row.pet_name,
      row.pet_type,
      moment(row.completed_on).format('DD-MM-YYYY'),
      moment(row.date).format('DD-MM-YYYY'),
      row.time_slot,
      moment(row.booking_date).format('DD-MM-YYYY'),
      row.pay_on_service === true ? 'Pay after service' : "Online",
      row.price_paid,
      row.payment_method === '' ? 'Not Paid' : `Paid${row.pay_on_service ? ` (${row.payment_method})` : ''}`,
      row.package_title,
      row.sitter_name,
      row.completed_sessions,
      row.started.before,
      row.started.equipment,
      row.started.after,
      // row.number_of_pets,
      // row.service_name,
      // row.payment_method === '' ? 'Pay after service' : 'Online',


      // row.payment_method === '' ? 'Not Paid' : 'Paid',

      // row.add_ons,
      // row.add_ons.map((addOn) => addOn.title).join(', '),

      // row.user_phone_no,
      // row.address,

      // row.sitter_phone,
      // row.no_of_sessions === 1 || row.no_of_sessions === 0 ? 'Single' : 'Subscription',
      // row.no_of_sessions,
      // row.ongoing_session_number,
      // row.remaining_sessions,
      // Add other columns based on your TABLE_HEAD structure
    ]);
  };


  const handleExportToExcel = () => {
    const formattedData = formatDataForExport();
    // const ws = XLSX.utils.aoa_to_sheet([TABLE_HEAD.map((head) => head.label), ...formattedData]);
    const ws = XLSX.utils.aoa_to_sheet([EXCEL_TABLE_HEAD.map((head) => head.label), ...formattedData]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'GroomingBookingCompleted');
    XLSX.writeFile(wb, 'GroomingBookingCompleted.xlsx');
  };

  const ExcelButton = styled(Button)({
    backgroundColor: '#217346', // Excel logo color
    color: '#ffffff', // Text color
    '&:hover': {
      backgroundColor: '#217346', // Hover color
    },
  });

  const [open, setOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const openModal = (rowData) => {
    // console.log('rowData', rowData);
    setSelectedRowData(rowData);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 'fit-to-content',
    bgcolor: '#e1f7f1',
    boxShadow: 10,
    borderRadius: '10px',
    border: '3px solid black',
    p: 4,
    backgroundImage: `url(${backgroundDetails})`, // Replace with the path to your image file
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
  return (
    <>
    {show ? 
      ( <CustomerOrderDetails setShow={setShow} />) : 
      (
          <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0}>
          <Typography variant="h4" gutterBottom>
            Booking Completed
          </Typography>
          {/* <BackButton /> */}
        </Stack>
        <Stack style={{ marginBottom: '10px' }}
          spacing={2}
          direction="row-reverse"
          alignItems="center"
          sx={{ display: 'flex' }}
        >
          <Grid container style={{ display: 'flex', justifyContent: 'end' }}>
            <Grid item xs={8} sm={8} md={8} lg={8} style={{ alignContent: 'right', paddingTop: '5px' }}>
              {/* <RangePicker onChange={datefilterhandleChange} 
              defaultValue={[defaultStartDate, defaultEndDate]}
              /> */}

              <RangePicker
                defaultValue={[defaultStartDate, defaultEndDate]}
                open={popupOpen}
                onOpenChange={(open) => {
                  if (open && defaultStartDate && defaultStartDate[0] && defaultStartDate[1]) {
                    setPopupOpen(true);
                  } else {
                    setPopupOpen(open);
                  }
                }}
                onChange={datefilterhandleChange}
                renderExtraFooter={() => (
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "8px" }}>
                    <Button onClick={() => { setPopupOpen(false); bookingCompleted(); }} variant='contained' size='small' >Submit</Button>
                  </Box>
                )}
              />

              <Select
                style={{ margin: '0px 0px  0px 2px', height: '34px' }}
                labelId="allocation-label"
                id="allocation"
                value={selectedDate}
                onChange={handleChangeDate}
              >
                <MenuItem value="All" disabled>Select </MenuItem>
                <MenuItem value="date">Start Date</MenuItem>
                <MenuItem value="created_at" selected> Booking Date</MenuItem>
                <MenuItem value="completed_on" selected> Completed Date</MenuItem>

              </Select>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                fullWidth
                label="Search user name"
                id="fullWidth"
                InputProps={{
                  style: { margin: '6px 2px', height: '34px' },
                  endAdornment: (
                    <InputAdornment position="end" style={{ cursor: 'pointer' }}
                    // onClick={handleSearch}
                    >
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: { fontSize: '16px', marginTop: '-4px' },
                }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value.replace(/[^A-Za-z0-9]/g, ''))}
              // onKeyDown={handleKeyPress}
              />
            </Grid>
          </Grid>
        </Stack>
        <Card>
          {/* <BookingCompletedListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}
          {loading && <CircularProgress style={{ position: 'absolute', top: '10%', left: '50%' }} />}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {filteredData.length === 0 ? (
                <Typography component="div">
                  {/* <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box> */}
                  {loading ? (
                    <Box sx={{ textAlign: 'center', m: 1 }}>Wait, data is loading...</Box>
                  ) : (
                    <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                  )}
                </Typography>
              ) : (
                <Table size='small'>
                  <BookingCompletedListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredData.map((row) => {
                      const {
                        _id,
                        pet_name,
                        pet_type,
                        number_of_pets,
                        service_name,
                        date,
                        time_slot,
                        customer_name,
                        payment_method,
                        price_paid,
                        add_ons,
                        package_title,
                        user_phone_no,
                        location,
                        sitter_name,
                        sitter_phone,
                        no_of_sessions,
                        booking_date,
                        completed_sessions,
                        ongoing_session_number,
                        remaining_sessions,
                        started,
                        pay_on_service,
                        booking_ref_id,
                        completed_on,
                        session_ref_id,
                      } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}

                        >
                          {/* <TableCell align="left" sx={{ textAlign: 'center' }}>
                            <Link className='details-using-ref-id' to={'/dashboard/customerorderDetails'} onClick={() => handleReferenceID(booking_ref_id, _id)}>
                              {no_of_sessions <= 1 ? booking_ref_id : session_ref_id}
                            </Link>
                          </TableCell> */}
                            <TableCell sx={{ textAlign: 'center' }} onClick={() => handleReferenceID(booking_ref_id, _id)} >
                              {/* <Link className='details-using-ref-id' to={'/dashboard/customerorderDetails'} onClick={() => handleReferenceID(booking_ref_id, _id)}> */}
                                {/* {booking_ref_id} */}
                               <span className='booking-ref-ids'> {no_of_sessions <= 1 ? booking_ref_id : session_ref_id}</span>
                              {/* </Link> */}
                            </TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{customer_name}</TableCell>
                          {/* <TableCell component="th" scope="row" sx={{ textAlign: 'center' }}>
                              <Typography variant="subtitle2" noWrap>
                                {pet_name}
                              </Typography>
                          </TableCell> */}
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{pet_type}</TableCell>
                          {/* <TableCell align="left" sx={{ textAlign: 'center' }}>{number_of_pets}</TableCell> */}
                          {/* <TableCell align="left" sx={{ textAlign: 'center' }}>{service_name}</TableCell> */}
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{completed_on ? moment(completed_on).format('DD-MM-YYYY') : "-"}</TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{moment(date).format('DD-MM-YYYY')}</TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{time_slot}</TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{moment(booking_date).format('DD-MM-YYYY')}</TableCell>
                          {/* <TableCell align="left" sx={{ textAlign: 'center' }}>{payment_method === '' ? 'Pay after service' : payment_method}</TableCell> */}
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{pay_on_service === true ? 'Pay after service' : "Online"}</TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{price_paid}</TableCell>
                          {/* <TableCell align="left" sx={{ textAlign: 'center' }}>{payment_method === '' ? 'Not Paid' : 'Paid'}</TableCell> */}
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{payment_method === '' ? 'Not Paid' : `Paid${pay_on_service ? ` (${payment_method})` : ''}`}</TableCell>
                          {/* <TableCell component="th" scope="row" sx={{ textAlign: 'center' }}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {add_ons.map((d, i) => (
                                <Typography variant="subtitle2" wordWrap>
                                  {d.title}
                                </Typography>
                              ))}
                            </Stack>
                          </TableCell> */}
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{package_title}</TableCell>
                          {/* <TableCell align="left" sx={{ textAlign: 'center' }}>{user_phone_no}</TableCell> */}
                          {/* <TableCell align="left" sx={{ textAlign: 'center' }}>
                            <a
                              style={{ textDecoration: 'none', color: '#32a852' }}
                              href={`https://www.google.com/maps?q=${location.coordinates[0]},${location.coordinates[1]}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View on Google Map
                            </a>
                          </TableCell> */}
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{sitter_name}</TableCell>
                          {/* <TableCell align="left" sx={{ textAlign: 'center' }}>{sitter_phone}</TableCell> */}
                          {/* <TableCell  sx={{ textAlign: 'center' }}>
                            {no_of_sessions === 1 || no_of_sessions === 0 ? 'Single' : 'Subscription'}
                          </TableCell>
                          <TableCell  sx={{ textAlign: 'center' }}>{no_of_sessions}</TableCell> */}
                          <TableCell sx={{ textAlign: 'center' }}>{completed_sessions}</TableCell>
                          {/* <TableCell  sx={{ textAlign: 'center' }}>{ongoing_session_number}</TableCell>
                          <TableCell  sx={{ textAlign: 'center' }}>{remaining_sessions}</TableCell> */}
                          <TableCell sx={{ textAlign: 'center' }}>
                            {/* <Button onClick={handleImagePopupOpen(row)}>View</Button> */}
                            <Button onClick={() => openModal(row.started)}>View</Button>
                          </TableCell>

                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 150]}
            component="div"
            // count={data.length}
            count={data.total_number_of_records}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <div style={{ textAlign: 'end', marginTop: '4%' }}>
          <ExcelButton variant="contained" disabled={prosdata == null} onClick={handleExportToExcel}>
            <img
              // src="https://wallpapers.com/images/hd/excel-microsoft-2013-application-logo-oo7x8r2rnik4ohlo.jpg"
              src={ExcelLogo}
              alt="Export To Excel"
              height={28}
              width={40}
            />
            <Typography>Export to Excel</Typography>
          </ExcelButton>
        </div>

      </Container>



      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px' }}>Grooming Completed Images</p>
          <Grid container>
            <Grid xs={4} sx={{ padding: '14px' }}>
              Equipment
              <img src={`${selectedRowData.equipment}`} alt="img" />
            </Grid>

            <Grid xs={4} sx={{ padding: '14px' }}>
              Before
              <img src={`${selectedRowData.before}`} alt="img" />
            </Grid>

            <Grid xs={4} sx={{ padding: '14px' }}>
              After
              <img src={`${selectedRowData.after}`} alt="img" />
            </Grid>

          </Grid>
        </Box>
      </Modal>
    </Page>
    )}
    </>
  );
}
